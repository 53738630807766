import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

const Title = styled.h4`
  width: 100%;
  border-bottom: 1px solid #fff;
  padding: 0;
  margin: 0.5em 0;
  padding-bottom: 0.2em;
  font-size: 2em;
  text-align: center;
`;

const VideosContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  > * {
    flex-grow: 1;
    flex-basis: 50%;
    display: flex;
    justify-content: center;
  }
`;

const Iframe = styled.iframe`
  margin: 1em auto;
`;

interface Props {
  title: string;
  videos: string[];
}

const VideoSection: React.FC<Props> = ({ title, videos }) => (
  <Container>
    <Title>{title}</Title>

    <VideosContainer>
      {videos.map(url => (
        <div key={url}>
          <Iframe
            width="560"
            height="315"
            src={url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      ))}
    </VideosContainer>
  </Container>
);

export default VideoSection;
