import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    background: #1a1a1a;
    color: #f5f5f5;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
  }

  html, body, #root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: #0082d8;
    transition: all 0.3s;

    :hover {
      color: #009aff;
    }
  }

`;
