import React, { useState } from 'react';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';

import { Input, Button } from '../styles';
import { useStoreActions } from '../store/hooks';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto auto;
  grid-row-gap: 1em;
  width: 20%;

  border: 1px solid #666;
  padding: 2em 0;

  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.75);

  > * {
    width: 50%;
    margin: 0 auto;
  }

  > button {
    margin: 1em auto 0;
  }

  > a {
    text-align: center;
  }
`;

const Error = styled.span`
  color: #ef3737;
  text-align: center;
`;

const SignUpForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | undefined>(undefined);

  const login = useStoreActions(actions => actions.auth.login);

  const onSignUp = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    setError(undefined);

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    try {
      const res = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      });

      if (!res || !res.user) {
        setError('Signup failed');
        return;
      }

      const { signInUserSession } = await Auth.signIn(email, password);
      const userInfo = await Auth.currentUserInfo();

      login({ user: userInfo, session: signInUserSession });
    } catch (err) {
      console.log(err);

      setError(err.message);
    }
  };

  return (
    <Container>
      <Form onSubmit={onSignUp}>
        <Input
          placeholder="Email"
          name="email"
          type="email"
          value={email}
          onChange={(e): void => {
            setEmail(e.target.value);
          }}
        />
        <Input
          placeholder="Password"
          name="password"
          type="password"
          value={password}
          onChange={(e): void => {
            setPassword(e.target.value);
          }}
        />
        <Input
          placeholder="Confirm password"
          name="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={(e): void => {
            setConfirmPassword(e.target.value);
          }}
        />

        <Button type="submit">Sign up</Button>

        <Error>{error}</Error>
      </Form>
    </Container>
  );
};

export default SignUpForm;
