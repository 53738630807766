import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import { StoreProvider } from 'easy-peasy';

import * as serviceWorker from './serviceWorker';
import Root from './Root';
import GlobalStyle from './styles/global';
import AWSConfig from './aws-config';
import store from './store';

Amplify.configure(AWSConfig);

ReactDOM.render(
  <StoreProvider store={store}>
    <BrowserRouter>
      <GlobalStyle />
      <Root />
    </BrowserRouter>
  </StoreProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
