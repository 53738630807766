import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

import Header from './Header';
import Main from './Main';
import { useStoreState } from '../store/hooks';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-row-gap: 1em;
  height: 100%;
  width: 100%;
  position: relative;
`;

const Footer = styled.footer`
  text-align: center;
  padding: 2em 0;
  height: 100%;
  width: 100%;
`;

const App: React.FC = () => {
  const user = useStoreState(state => state.auth.user);

  if (!user) {
    return <Redirect to="/login" />;
  }

  return (
    <Container>
      <Header />

      <Main />

      <Footer>Directive Games ©2019</Footer>
    </Container>
  );
};

export default App;
