import React from 'react';
import styled from 'styled-components';

import VideoSection from '../components/VideoSection';

const Background = styled.div`
  background-color: #222;
`;

const Wrapper = styled.main`
  width: 90%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
`;

const Main: React.FC = () => (
  <Background>
    <Wrapper>
      <VideoSection
        title="Project setup"
        videos={[
          'https://www.youtube.com/embed/W-rDrUtbIWM',
          'https://www.youtube.com/embed/sMAh1yKeaJM',
          'https://www.youtube.com/embed/8c-O2oPYvYc',
          'https://www.youtube.com/embed/hLtXmjQzD04',
        ]}
      />
    </Wrapper>
  </Background>
);

export default Main;
