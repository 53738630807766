import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { Input, Button } from '../styles';
import { useStoreActions } from '../store/hooks';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto auto auto;
  grid-row-gap: 1em;
  width: 20%;

  border: 1px solid #666;
  padding: 2em 0;

  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.75);

  > * {
    width: 50%;
    margin: 0 auto;
  }

  > button {
    margin: 1em auto;
  }

  > a {
    text-align: center;
  }
`;

const Title = styled.h1`
  text-align: center;
  text-transform: uppercase;
  text-indent: 5px;
  letter-spacing: 5px;
  padding: 0;
  margin: 0.5em auto;
`;

const Error = styled.span`
  color: #ef3737;
  text-align: center;
`;

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | undefined>(undefined);

  const login = useStoreActions(actions => actions.auth.login);

  const onLogin = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    setError(undefined);

    try {
      const { signInUserSession } = await Auth.signIn(email, password);
      const userInfo = await Auth.currentUserInfo();

      login({ user: userInfo, session: signInUserSession });
    } catch (err) {
      console.log(err);

      setError(err.message);
    }
  };

  return (
    <Container>
      <Form onSubmit={onLogin}>
        <Title>Kamo</Title>

        <Input
          placeholder="Email"
          type="email"
          name="email"
          value={email}
          onChange={(e): void => {
            setEmail(e.target.value);
          }}
        />
        <Input
          placeholder="Password"
          type="password"
          name="password"
          value={password}
          onChange={(e): void => {
            setPassword(e.target.value);
          }}
        />

        <Button type="submit">Login</Button>

        <Error>{error}</Error>

        <Link to="/login/signup">Sign up</Link>
      </Form>
    </Container>
  );
};

export default LoginForm;
