/* eslint-disable no-param-reassign */
import { Action, action, Thunk, thunk } from 'easy-peasy';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

interface UserAttributes {
  sub: string;
  email_verified: boolean;
  email: string;
}

export interface User {
  id: string;
  username: string;
  attributes: UserAttributes;
}

interface LoginPayload {
  session?: CognitoUserSession;
  user: User;
}

export interface AuthModel {
  session?: CognitoUserSession;
  user?: User;
  login: Action<AuthModel, LoginPayload>;
  logout: Thunk<AuthModel>;
  reset: Action<AuthModel>;
}

const authModel: AuthModel = {
  session: undefined,
  user: undefined,
  login: action((state, payload) => {
    state.session = payload.session;
    state.user = payload.user;
  }),
  logout: thunk(async actions => {
    await Auth.signOut();
    actions.reset();
  }),
  reset: action(state => {
    state.session = undefined;
    state.user = undefined;
  }),
};

export default authModel;
