import auth, { AuthModel } from './auth';

export interface StoreModel {
  auth: AuthModel;
}

const storeModel: StoreModel = {
  auth,
};

export default storeModel;
