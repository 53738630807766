export default {
  Auth: {
    region: 'eu-west-1',
    identityPoolId: 'eu-west-1:2787db13-781c-492c-ac4c-8217aaaa625f',
    userPoolId: 'eu-west-1_GPuDegj6k',
    userPoolWebClientId: '6jse8in0bbsao0ipl8lo2vjpff',
  },
  Analytics: {
    disabled: true,
  },
  Storage: {
    AWSS3: {
      bucket: 'kamo.io',
      region: 'eu-west-1',
      level: 'public',
      customPrefix: {
        public: 'preview/',
      },
    },
  },
};
