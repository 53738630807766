import styled from 'styled-components';

export const Input = styled.input`
  background: none;
  border: 1px solid #666;
  border-radius: 2px;
  padding: 1em;
  color: #f1f1f1;
`;

export const Button = styled.button`
  display: inline-block;
  padding: 0.75em;
  border: 0.1em solid #f1f1f1;
  border-radius: 0.12em;
  text-decoration: none;
  font-size: 1em;
  color: #f1f1f1;
  text-align: center;
  transition: all 0.3s;
  background: none;
  cursor: pointer;

  :hover {
    color: #000;
    background-color: #f1f1f1;
  }
`;

Button.defaultProps = {
  type: 'button',
};
