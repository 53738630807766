import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import App from './App';
import Login from './Login';
import { useStoreState, useStoreActions } from './store/hooks';
import { User } from './store/auth';

const Root: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const user = useStoreState(state => state.auth.user);
  const login = useStoreActions(actions => actions.auth.login);

  const getUser = async (): Promise<{ userInfo?: User; session?: CognitoUserSession }> => {
    try {
      const session = await Auth.currentSession();
      const userInfo = await Auth.currentUserInfo();

      return { userInfo, session };
    } catch (err) {
      if (err !== 'not authenticated' && err !== 'No current user') {
        throw err;
      }

      return {};
    }
  };

  const checkSession = async (): Promise<JSX.Element | undefined> => {
    try {
      const { session, userInfo } = await getUser();

      if (!userInfo) {
        return <Redirect to="/login" />;
      }

      login({ user: userInfo, session });
    } catch (err) {
      if (err !== 'not authenticated') {
        console.log(err);
      }
    } finally {
      setLoading(false);
    }

    return undefined;
  };

  if (loading) {
    if (!user) {
      checkSession();
    }

    return <h3>Loading...</h3>;
  }

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route component={App} />
    </Switch>
  );
};

export default Root;
