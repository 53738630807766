import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import { useStoreState } from '../store/hooks';

const Login: React.FC = () => {
  const user = useStoreState(state => state.auth.user);

  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route exact path="/login" component={LoginForm} />
      <Route exact path="/login/signup" component={SignUpForm} />
    </Switch>
  );
};

export default Login;
