import React from 'react';
import styled from 'styled-components';
import { Storage } from 'aws-amplify';

import { useStoreState, useStoreActions } from '../store/hooks';

const Wrapper = styled.header`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1em;

  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.h1`
  text-align: center;
  text-transform: uppercase;
  font-size: 5em;
  margin: 0;
  margin-top: 0.5em;
  letter-spacing: 0.25em;
  text-indent: 0.25em;
`;

const SubTitle = styled.h1`
  text-align: center;
  font-size: 2em;
  font-weight: 300;
  margin: 0;
  margin-top: 0;
`;

const Button = styled.button.attrs({ type: 'button' })`
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #fff;
  border-radius: 0.12em;
  text-decoration: none;
  font-size: 1.5em;
  color: #fff;
  text-align: center;
  transition: all 0.3s;
  background: none;
  cursor: pointer;
  width: 40%;
  margin: 1.5em auto 0;

  :hover {
    color: #000;
    background-color: #fff;
  }
`;

const User = styled.div`
  position: absolute;
  top: 1.5em;
  right: 6em;

  > * {
    margin-left: 1em;
  }
`;

const Logout = styled.span`
  color: #0082d8;
  transition: all 0.3s;
  cursor: pointer;

  :hover {
    color: #009aff;
  }
`;

const Version = styled.span`
  display: block;
  text-align: center;
  font-weight: 300;
  font-style: italic;
  color: rgba(255, 255, 255, 0.4);
  margin: 0.5em 0;
`;

const Header: React.FC = () => {
  const user = useStoreState(state => state.auth.user);
  const logout = useStoreActions(actions => actions.auth.logout);

  const onLogout = (): void => {
    logout();
  };

  const onDownload = async (): Promise<void> => {
    const url = await Storage.get('builds/kamo-1.0.19-e0ed02b.zip');

    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', url as string);

    document.body.appendChild(downloadAnchorNode);

    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  return (
    <>
      <User>
        <span>{user?.attributes.email}</span>
        <Logout onClick={onLogout}>Logout</Logout>
      </User>

      <Wrapper>
        <Title>Kamo</Title>

        <SubTitle>Preview</SubTitle>

        <Button onClick={onDownload}>Download</Button>
        <Version>1.0.19-e0ed02b</Version>
      </Wrapper>
    </>
  );
};

export default Header;
